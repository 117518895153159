/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
  width: 100%;
}

mat-button-toggle-group {
  width: 100%;
}

mat-button-toggle {
  flex: 1 1 100%;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  display: flex;
}

mat-label:after {
  content: ":";
}

.right-aligned-actions > button:not(:last-child) {
  margin-right: 10px;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
  display: inline-block;
}

app-responsive-table {
  &.row-clickable {
    .mat-mdc-row {
      cursor: pointer;
    }
  }
}
